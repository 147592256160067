// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../styleguide/components/Paragraph/P.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../styleguide/components/Heading/H3.res.js";
import * as H4 from "../../../styleguide/components/Heading/H4.res.js";
import * as Svg from "../../../styleguide/components/Svg/Svg.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as HelpBlock from "../help-block/HelpBlock.res.js";
import * as ThanksScss from "./Thanks.scss";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import * as Routes_Location from "../../../routes/common/Routes_Location.res.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";

var css = ThanksScss;

function ThanksForSignup$default(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(H2.make, {
                                          children: "Welcome to Datacenters.com"
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: "Datacenters.com is a technology marketplace and aggregator. We offer data center colocation, cloud, connectivity, hardware, software, and managed services from providers worldwide.",
                                          className: css.thanksIntro
                                        }),
                                    JsxRuntime.jsx(H3.make, {
                                          children: "What You Can Find on DataCenters.com"
                                        }),
                                    JsxRuntime.jsx(Link.make, {
                                          href: Routes_Location.index,
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Svg.make, {
                                                        title: "Directory",
                                                        viewBoxWidth: "60",
                                                        viewBoxHeight: "60",
                                                        children: JsxRuntime.jsxs("g", {
                                                              children: [
                                                                JsxRuntime.jsx("path", {
                                                                      className: css.svgPathDirectory,
                                                                      d: "M12 1.5C11.7 1.5 11.5 1.7 11.5 2L11.5 48C11.5 48.3 11.7 48.5 12 48.5L58 48.5C58.3 48.5 58.5 48.3 58.5 48L58.5 2C58.5 1.7 58.3 1.5 58 1.5L12 1.5Z"
                                                                    }),
                                                                JsxRuntime.jsx("path", {
                                                                      className: css.svgPathDirectory,
                                                                      d: "M2 11.5C1.7 11.5 1.5 11.7 1.5 12L1.5 58C1.5 58.3 1.7 58.5 2 58.5L48 58.5C48.3 58.5 48.5 58.3 48.5 58L48.5 12C48.5 11.7 48.3 11.5 48 11.5L2 11.5Z"
                                                                    }),
                                                                JsxRuntime.jsx("path", {
                                                                      d: "M13.5 22L36.5 22C37.3 22 38 22.7 38 23.5L38 46.5C38 47.3 37.3 48 36.5 48L13.5 48C12.7 48 12 47.3 12 46.5L12 23.5C12 22.7 12.7 22 13.5 22ZM19.5 30C18.7 30 18 30.7 18 31.5 18 32.3 18.7 33 19.5 33L30.5 33C31.3 33 32 32.3 32 31.5 32 30.7 31.3 30 30.5 30L19.5 30ZM19.5 37C18.7 37 18 37.7 18 38.5 18 39.3 18.7 40 19.5 40L26.5 40C27.3 40 28 39.3 28 38.5 28 37.7 27.3 37 26.5 37L19.5 37Z",
                                                                      fill: "#18C3BB"
                                                                    })
                                                              ],
                                                              fill: "none"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx(H4.make, {
                                                                children: "Data Center Directory"
                                                              }),
                                                          JsxRuntime.jsx(P.make, {
                                                                children: "Research data centers, cloud nodes, internet exchanges (IX), disaster recovery (DR) sites globally."
                                                              })
                                                        ],
                                                        className: css.serviceText
                                                      })
                                                ],
                                                className: css.serviceBlock
                                              })
                                        }),
                                    JsxRuntime.jsx(Link.make, {
                                          href: Routes_Project.Colocation.$$new,
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Svg.make, {
                                                        title: "Colocation Project",
                                                        viewBoxWidth: "60",
                                                        viewBoxHeight: "60",
                                                        children: JsxRuntime.jsxs("g", {
                                                              children: [
                                                                JsxRuntime.jsx("rect", {
                                                                      className: css.svgPathMarketplace,
                                                                      height: "26",
                                                                      width: "26",
                                                                      rx: "2",
                                                                      x: "1.5",
                                                                      y: "1.5"
                                                                    }),
                                                                JsxRuntime.jsx("path", {
                                                                      d: "M33 0L58 0C59.1 0 60 0.9 60 2L60 27C60 28.1 59.1 29 58 29L33 29C31.9 29 31 28.1 31 27L31 2C31 0.9 31.9 0 33 0ZM40 8C39.4 8 39 8.4 39 9L39 20C39 20.6 39.4 21 40 21L51 21C51.6 21 52 20.6 52 20L52 9C52 8.4 51.6 8 51 8L40 8Z",
                                                                      fill: "#18C3BB"
                                                                    }),
                                                                JsxRuntime.jsx("rect", {
                                                                      className: css.svgPathMarketplace,
                                                                      height: "26",
                                                                      width: "26",
                                                                      rx: "2",
                                                                      x: "1.5",
                                                                      y: "32.5"
                                                                    }),
                                                                JsxRuntime.jsx("rect", {
                                                                      className: css.svgPathMarketplace,
                                                                      height: "26",
                                                                      width: "26",
                                                                      rx: "2",
                                                                      x: "32.5",
                                                                      y: "32.5"
                                                                    })
                                                              ],
                                                              fill: "none"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx(H4.make, {
                                                                children: "Start a Colocation RFP"
                                                              }),
                                                          JsxRuntime.jsx(P.make, {
                                                                children: "Create a custom Colocation RFP and get pricing from multiple providers."
                                                              })
                                                        ],
                                                        className: css.serviceText
                                                      })
                                                ],
                                                className: css.serviceBlock
                                              })
                                        }),
                                    JsxRuntime.jsx(Link.make, {
                                          href: Routes_Resource.resourcesIndex,
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Svg.make, {
                                                        title: "Resources",
                                                        viewBoxWidth: "60",
                                                        viewBoxHeight: "60",
                                                        children: JsxRuntime.jsxs("g", {
                                                              children: [
                                                                JsxRuntime.jsx("path", {
                                                                      style: {
                                                                        fill: "#FFF",
                                                                        stroke: "#98A0B0",
                                                                        strokeWidth: "3"
                                                                      },
                                                                      d: "M2 1.5C1.7 1.5 1.5 1.7 1.5 2L1.5 58C1.5 58.3 1.7 58.5 2 58.5L50 58.5C50.3 58.5 50.5 58.3 50.5 58L50.5 6.8C50.5 6.7 50.4 6.6 50.4 6.5L45.5 1.6C45.4 1.6 45.3 1.5 45.2 1.5L2 1.5Z"
                                                                    }),
                                                                JsxRuntime.jsx("rect", {
                                                                      className: css.svgPathResources,
                                                                      height: "4",
                                                                      width: "28",
                                                                      rx: "1",
                                                                      x: "12",
                                                                      y: "13"
                                                                    }),
                                                                JsxRuntime.jsx("rect", {
                                                                      className: css.svgPathResources,
                                                                      height: "4",
                                                                      width: "28",
                                                                      rx: "1",
                                                                      x: "12",
                                                                      y: "23"
                                                                    }),
                                                                JsxRuntime.jsx("rect", {
                                                                      className: css.svgPathResources,
                                                                      height: "4",
                                                                      width: "28",
                                                                      rx: "1",
                                                                      x: "12",
                                                                      y: "33"
                                                                    }),
                                                                JsxRuntime.jsx("rect", {
                                                                      className: css.svgPathResources,
                                                                      height: "4",
                                                                      width: "19",
                                                                      rx: "1",
                                                                      x: "12",
                                                                      y: "43"
                                                                    })
                                                              ],
                                                              fill: "none"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx(H4.make, {
                                                                children: "Downloadable Resources"
                                                              }),
                                                          JsxRuntime.jsx(P.make, {
                                                                children: "Download data center checklists, plans, case studies, white papers, and reports from top providers."
                                                              })
                                                        ],
                                                        className: css.serviceText
                                                      })
                                                ],
                                                className: css.serviceBlock
                                              })
                                        })
                                  ],
                                  className: css.thanks
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(HelpBlock.make, {}),
                                  className: Cx.cx([
                                        css.column,
                                        css.helpContainer
                                      ])
                                })
                          ],
                          className: css.row
                        }),
                    className: css.container
                  }),
              className: css.thanksContainer
            });
}

var $$default = ThanksForSignup$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
