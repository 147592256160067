// extracted by mini-css-extract-plugin
export var column = "Thanks__column__nQYsB";
export var container = "Thanks__container__jgG0g";
export var flex = "Thanks__flex__ehtH9";
export var flexColumn = "Thanks__flexColumn__iUpU6";
export var gap1 = "Thanks__gap1__j2D5S";
export var gap2 = "Thanks__gap2__Xo13w";
export var gap3 = "Thanks__gap3__4Tkwk";
export var gap4 = "Thanks__gap4__RxSMM";
export var gap5 = "Thanks__gap5__QWuCt";
export var helpContainer = "Thanks__helpContainer__tdo3b";
export var row = "Thanks__row__EgNh1";
export var serviceBlock = "Thanks__serviceBlock__wW2Fh";
export var serviceText = "Thanks__serviceText__R2fTR";
export var svgPathDirectory = "Thanks__svgPathDirectory__xfoTY";
export var svgPathMarketplace = "Thanks__svgPathMarketplace__kCIfY";
export var svgPathRealA = "Thanks__svgPathRealA__Eyi5_";
export var svgPathRealB = "Thanks__svgPathRealB__YpSU7";
export var svgPathResources = "Thanks__svgPathResources__aZb8o";
export var thanks = "Thanks__thanks__sawVF";
export var thanksContainer = "Thanks__thanksContainer__RxRJe";
export var thanksIntro = "Thanks__thanksIntro__uPBLD";